<template>
  <div>
    <FormBuilderUsersData></FormBuilderUsersData>
  </div>
</template>
<script>
import FormBuilderUsersData from "../../components/formBuilders/FormBuilderUsersData";
export default {
  components: {
    FormBuilderUsersData
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
</style>